<template>
  <div class="page-politicas">
    <div class="mt-3 w-100"></div>
    <div v-if="dataPolitics" class="container-polices">
      <div class="col-12 col-md-4 col-xl-3 options-polices">
        <div class="py-2 mt-4 px-4 w-100 text-center" @click="isMovilScroll()">
          <div v-for="(politic, index) in dataPolitics" :key="index">
            <router-link
              v-if="politic.tipo_pagina == 0"
              :to="{ name: 'HabeasData', params: { politica: politic.slug } }"
            >
              <div
                class="btn-option-police mb-3"
                :class="
                  politic.slug == $route.params.politica
                    ? 'btn-option-police-selected'
                    : ''
                "
              >
                {{ politic.titulo }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div
        v-if="dataPolitics"
        class="col-12 col-md-8 col-xl-9 html-politics"
        v-html="getDataPolitic.descripcion"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      optionSelect: 0,
      dataPolitics: null,
    }
  },
  computed: {
    getDataPolitic() {
      if (!this.dataPolitics) {
        return ''
      }
      return this.dataPolitics.find(e => {
        return e.slug == this.$route.params.politica
      })
    },
  },
  watch: {
    $route() {
      document.title = this.getDataPolitic.titulo + ' | Clover Tienda Online'
      // this.$gtag.pageview(this.$route.fullPath)
    },
  },
  mounted() {
    document.title = 'Politicas | Clover Tienda Online'
    if (!this.dataPolitics) {
      this.getPolitics()
    }
    // this.$gtag.pageview(this.$route.fullPath)
  },
  methods: {
    isMovilScroll() {
      if (window.screen.width < 768) {
        window.scrollTo(0, 587)
      }
    },
    async getPolitics() {
      await this.axios({
        method: 'GET',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/layout/get_footer',
      })
        .then(response => {
          this.dataPolitics = response.data.paginas
          document.title =
            this.getDataPolitic.titulo + ' | Clover Tienda Online'
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
